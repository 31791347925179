'use strict';

angular.module("aerosApp")
	.factory('newProjectModal', [ "$uibModal", function ($uibModal) {

		function open(title, projectName, projectComment, fiberGroupsFile, fileType, submitLabel) {
			var modal = $uibModal.open({
                size: "md",
				templateUrl: "/static/templates/dashboard/project/modal/newProjectModal.html",
				controller: "NewProjectModalCtrl",
				resolve: {
					title: function() { return title; },
					projectName: function() { return projectName; },
					projectComment: function() { return projectComment; },
					fiberGroupsFile: function() { return fiberGroupsFile; },
					fileType: function() { return fileType; },
					submitLabel: function () { return submitLabel; }
				}
			});
			return modal.result;
		}

		return {
			open: open
		};
	}])
	.controller("NewProjectModalCtrl", [ "$scope", "$uibModalInstance", "title", "projectName", "projectComment", "fiberGroupsFile", "fileType", "submitLabel", function($scope, $uibModalInstance, title, projectName, projectComment, fiberGroupsFile, fileType, submitLabel) {
		$scope.title = title;
		$scope.newProject = {
			name: projectName,
			comment: projectComment,
			file: fiberGroupsFile,
			type: fileType 
		};
		$scope.submitLabel = submitLabel || "Create Project";

		$scope.cancel = function() {
			$uibModalInstance.dismiss();
		};

		$scope.saveProject = function() {
			$uibModalInstance.close($scope.newProject);
		};
	}]);